<template>
	<div class="eurial-box">
		<div>
			<div class="eurial-search-box">
				<div class="block-img">
					<el-carousel height="390px" arrow="nerver">
						<el-carousel-item v-for="item in imgItem" :key="item">
							<el-image :src="item.img" style="width:100%;height:100%"></el-image>
						</el-carousel-item>
					</el-carousel>
				</div>
				<div class="eurail-outner">
					<div class="eurail-search">
						<div class="eurail-title">
							<el-row class="nav nav-tabs" type="flex">
								<div :class="(currentNav==1)?'ft16 text-c active ml10':'ft16 text-c ml0'" @click="changePoint(1)">
									欧铁点到点</div>
								<div :class="(currentNav==2)?'ft16 text-c active':'ft16 text-c'" @click="changePoint(2)">
									欧铁通票
								</div>
							</el-row>
							<div class="eurail-boxtwo">
								<div class="tab-content eurail">
									<div class="tab-pane active" v-if="currentNav==1">
										<div class="eurail-item">
											<div class="start-city">
												<div>
													<div class="mt10 mb5">出发城市</div>
													<div class="input-b">
														<el-input type="text" name="name" value="巴黎(FRPAR)" class="form-control"></el-input>
													</div>
												</div>
												<div class="ml10 mr10"><span class="iconfont icon-wangfan icon-zhuanhuan"></span></div>
												<div>
													<div class="mt10 mb5">到达城市</div>
													<div class="input-b">
														<el-input type="text" name="name" value="巴塞尔(CHAJP)" class="form-control"></el-input>
													</div>
												</div>
											</div>
											<div class="start-city">
												<div>
													<div class="mt15 mb5">出发日期</div>
													<div class="input-b">
														<el-date-picker
														      v-model="value1"
														      type="date"
															  style="width:100%;"
														      placeholder="选择日期">
														    </el-date-picker>
													</div>
												</div>
												<div class="empty-box"></div>
												<div>
													<div class="mt15 mb5">返回日期</div>
													<div class="input-b">
														<el-date-picker
														      v-model="value1"
														      type="date"
															  style="width:100%;"
														      placeholder="选择日期">
														    </el-date-picker>
													</div>
												</div>
											</div>
											<el-dropdown :hide-on-click="false">
												<div>
													<div class="mt15 mb5">受客人数</div>
													<div class="people-btn">
														<div class="fn lb-box">
															<span>1</span>
															<span>成人</span>
															<span>1</span>
															<span>儿童</span>
															<span>1</span>
															<span>青年</span>
															<span>1</span>
															<span>长者</span>
															<span class="iconfont icon-xiala icon-xiala2"></span>
														</div>
													</div>
												</div>
												<el-dropdown-menu slot="dropdown" style="width:400px" class="choose-boxs">
													<div class="pover-box ft14">
														<div class="pt15 pb15 b-line text-c">
															<span class="iconfont icon-sub"></span>
															<div class="i-box">
																<div>
																	<span class="num">1</span><span class="ft12 gray9">名青年</span></div>
															</div><span class="iconfont icon-add"></span>
														</div>
														<div class="children text-c">
															<span class="iconfont icon-sub"></span>
															<div class="i-box">
																<div><span class="num">1</span>
																	<span class="ft12 gray9">名儿童</span>
																</div>
															</div><span class="iconfont icon-add"></span>
														</div>
														<div class="pt15 pb15 b-line text-c">
															<span class="iconfont icon-sub"></span>
															<div class="i-box">
																<div><span class="num">1</span>
																	<span class="ft12 gray9">名青年</span></div>
															</div><span class="iconfont icon-add"></span>
														</div>
														<div class="pt15 pb15 b-line text-c"><span class="iconfont icon-sub"></span>
															<div class="i-box">
																<div><span class="num">1</span><span class="ft12 gray9">名长者</span></div>
															</div><span class="iconfont icon-add"></span>
														</div>
													</div>
												</el-dropdown-menu>
											</el-dropdown>
											<div class="mt15 mb15 ft14 position-r">
												<button type="button" class="search-btn mr15">搜索</button>
												<div class="year-rule ml15">
													<el-dropdown>
														<span class="y-r">年龄规则</span>
														<el-dropdown-menu slot="dropdown" style="width:420px">
															<div class="age-rule">
																<p>当您搜索点对点火车票时，必须按照以下信息来选择所需车票。</p>
																<p>成人：26-59岁</p>
																<p>老人：60岁以上</p>
																<p>青年：25岁以下</p>
																<p>儿童：在欧洲，各国对于儿童的年龄界定是不同的。详见下表。</p>
																<table>
																	<tbody>
																		<tr>
																			<th class="th01">儿童的年龄范围</th>
																			<th>Countries（国家）</th>
																		</tr>
																		<tr>
																			<td class="th01">4-11</td>
																			<td>France, Croatia, Serbia, Bosnia, Greece, Italy, Macedonia, Netherlands, Poland, Romania, Spain.
																				（法
																				国、克罗地亚、塞班、波斯尼亚、希腊、意大利、马其顿、荷兰、波兰、罗马尼亚、西班牙）</td>
																		</tr>
																		<tr>
																			<td class="th01">4-12</td>
																			<td>Czech Republic（捷克）</td>
																		</tr>
																		<tr>
																			<td class="th01">4-16</td>
																			<td>Norway（挪威）</td>
																		</tr>
																		<tr>
																			<td class="th01">5-12</td>
																			<td>Portugal（葡萄牙）</td>
																		</tr>
																		<tr>
																			<td class="th01">6-11</td>
																			<td>Belgium, Bulgaria, Luxembourg, Slovakia, Slovenia.（比利时、保加利亚、卢森堡、斯洛伐克、斯洛文尼亚）</td>
																		</tr>
																		<tr>
																			<td class="th01">6-13</td>
																			<td>Hungary.（匈牙利）</td>
																		</tr>
																		<tr>
																			<td class="th01">6-14</td>
																			<td>Germany, Sweden（德国、瑞典）</td>
																		</tr>
																		<tr>
																			<td class="th01">6-15</td>
																			<td>Austria, Denmark, Switzerland.（澳大利亚、丹麦、瑞士）</td>
																		</tr>
																		<tr>
																			<td class="th01">6-16</td>
																			<td>Finland.（芬兰）</td>
																		</tr>
																	</tbody>
																</table>
																<p>婴儿：4岁或6岁以下。不占座婴儿可免费随成人同行。</p>
																<p>当您选择跨国境的铁路，儿童票只适用于4－11岁的孩子。</p>
																<p>当没有适用的老人票、青年票或儿童票时，您必须以成人票价订购。</p>
															</div>
														</el-dropdown-menu>
													</el-dropdown>
												</div>
											</div>
										</div>
									</div>
									<div class="tab-pane" v-if="currentNav==2">
										<div class="eurail-item">
											<div class="mt15 ft14 pl5">通票类型</div>
											<div class="start-city mt10 mb20 ft14">
												<div class="ticket-select">
													<el-select class="form-control">
													</el-select>
												</div>
												<div class="year-rule ml15 through-ticket">
													<el-dropdown>
														<span class="y-r">年龄规则</span>
														<el-dropdown-menu slot="dropdown" style="width:420px">
															<div class="age-rule">
																<p>当您搜索点对点火车票时，必须按照以下信息来选择所需车票。</p>
																<p>成人：26-59岁</p>
																<p>老人：60岁以上</p>
																<p>青年：25岁以下</p>
																<p>儿童：在欧洲，各国对于儿童的年龄界定是不同的。详见下表。</p>
																<table>
																	<tbody>
																		<tr>
																			<th class="th01">儿童的年龄范围</th>
																			<th>Countries（国家）</th>
																		</tr>
																		<tr>
																			<td class="th01">4-11</td>
																			<td>France, Croatia, Serbia, Bosnia, Greece, Italy, Macedonia, Netherlands, Poland, Romania, Spain.
																				（法
																				国、克罗地亚、塞班、波斯尼亚、希腊、意大利、马其顿、荷兰、波兰、罗马尼亚、西班牙）</td>
																		</tr>
																		<tr>
																			<td class="th01">4-12</td>
																			<td>Czech Republic（捷克）</td>
																		</tr>
																		<tr>
																			<td class="th01">4-16</td>
																			<td>Norway（挪威）</td>
																		</tr>
																		<tr>
																			<td class="th01">5-12</td>
																			<td>Portugal（葡萄牙）</td>
																		</tr>
																		<tr>
																			<td class="th01">6-11</td>
																			<td>Belgium, Bulgaria, Luxembourg, Slovakia, Slovenia.（比利时、保加利亚、卢森堡、斯洛伐克、斯洛文尼亚）</td>
																		</tr>
																		<tr>
																			<td class="th01">6-13</td>
																			<td>Hungary.（匈牙利）</td>
																		</tr>
																		<tr>
																			<td class="th01">6-14</td>
																			<td>Germany, Sweden（德国、瑞典）</td>
																		</tr>
																		<tr>
																			<td class="th01">6-15</td>
																			<td>Austria, Denmark, Switzerland.（澳大利亚、丹麦、瑞士）</td>
																		</tr>
																		<tr>
																			<td class="th01">6-16</td>
																			<td>Finland.（芬兰）</td>
																		</tr>
																	</tbody>
																</table>
																<p>婴儿：4岁或6岁以下。不占座婴儿可免费随成人同行。</p>
																<p>当您选择跨国境的铁路，儿童票只适用于4－11岁的孩子。</p>
																<p>当没有适用的老人票、青年票或儿童票时，您必须以成人票价订购。</p>
															</div>
														</el-dropdown-menu>
													</el-dropdown>


												</div>
											</div>
											<div>
												<div class="ft14 fb mb10 mt40">热门国家通票推荐</div>
												<div class="hot-country ft14">
													<span>瑞士</span>
													<span>俄国</span>
													<span>意大利</span>
													<span>西班牙</span>
													<span>荷兰</span>
													<span>法国</span>
													<span>比利时</span>
													<span>瑞典</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container mt10">
			<el-row type="flex">
				<div class="chujing-box">
					<span class="iconfont icon-chujingyou icon-chujing"></span>
					<span class="ft20 red">出国游热门通票</span>
				</div>
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="一国通票" name="first">
						<div class="specialbox">
							<div class="special-list" v-for="(item,index) in [1,2,3,4,5,6,7,8]" :key="index">
								<a class="special-content">
									<img src="../../assets/insurance/visabg-2.jpg" class="special-img">
									<div class="pd10 bg-white ft14">瑞士3天通票</div>
									<div class="special-tit ft12"><span class="trip gray9">广州-悉尼</span><span class="price">￥5960起</span></div>
								</a>
							</div>
						</div>
					</el-tab-pane>
					<el-tab-pane label="两国通票" name="second">
						<div class="specialbox">
							<div class="special-list" v-for="(item,index) in [1,2,3,4,5,6,7,8]" :key="index">
								<a class="special-content">
									<img src="../../assets/insurance/visabg-2.jpg" class="special-img">
									<div class="pd10 bg-white ft14">瑞士3天通票</div>
									<div class="special-tit ft12"><span class="trip gray9">广州-悉尼</span><span class="price">￥5960起</span></div>
								</a>
							</div>
						</div>
					</el-tab-pane>
					<el-tab-pane label="多国通票" name="third">
						<div class="specialbox">
							<div class="special-list" v-for="(item,index) in [1,2,3,4,5,6,7,8]" :key="index">
								<a class="special-content">
									<img src="../../assets/insurance/visabg-2.jpg" class="special-img">
									<div class="pd10 bg-white ft14">瑞士3天通票</div>
									<div class="special-tit ft12"><span class="trip gray9">广州-悉尼</span><span class="price">￥5960起</span></div>
								</a>
							</div>
						</div>
					</el-tab-pane>
				</el-tabs>
			</el-row>
			<div class="b-bottom pt15 pb10">
				<span class="iconfont icon-woshou"></span><span class="ft20 red">为什么选择我们</span>
			</div>
			<div class="advantage">
				<ul class="clearfix" style="overflow:hidden;">
					<li>
						<div class="advantage-inline">
							<img src="../../assets/eurail/24hour.png" alt="" style="width:52px;height:53px">
							<dl>
								<dt class="ft16 pt5 pb5">全天服务</dt>
								<dd class="pb5 ft14">24小时</dd>
								<dd class="ft14">全天候为您服务</dd>
							</dl>
						</div>
					</li>
					<li>
						<div class="advantage-inline">
							<img src="../../assets/eurail/safe.png" alt="" style="width:52px;height:53px">
							<dl>
								<dt class="ft16 pt5 pb5">全票保证</dt>
								<dd class="pb5 ft14">全程安全保障</dd>
								<dd class="ft14">售后五忧</dd>
							</dl>
						</div>
					</li>
					<li>
						<div class="advantage-inline">
							<img src="../../assets/eurail/pay.png" alt="" style="width:52px;height:53px">
							<dl>
								<dt class="ft16 pt5 pb5">安全支付</dt>
								<dd class="pb5 ft14">保障用户资金</dd>
								<dd class="ft14">交易安全</dd>
							</dl>
						</div>
					</li>
					<li>
						<div class="advantage-inline">
							<img src="../../assets/eurail/ad.png" alt="" style="width:52px;height:53px">
							<dl>
								<dt class="ft16 pt5 pb5">官方授权</dt>
								<dd class="pb5 ft14">中国官方代理出票机构</dd>
								<dd class="ft14">值得信赖</dd>
							</dl>
						</div>
					</li>
				</ul>
			</div>
			<div class="b-bottom pt15 pb10">
				<span class="iconfont icon-wenda"></span>
				<span class="ft20 red">热门回答</span><span class="ft14 gray9">(您99%问题可以在这里找到答案，或者致电我们400-678-6622)</span>
			</div>
			<div class="question  pb15" style="overflow: hidden;">
				<div class="question-list pt15" v-for="(item,index) in [1,2,3,4]" :key="index">
					<div>
						<span class="question-span mr10">问</span>
						<div class="ticket-status"><span class="ft16 black">关于注销欧铁通票、站对站车票、夜车和专利，欧铁头什么政策</span></div>
					</div>
					<div class="pl36 pt10 pb10">
						<img src="../../assets/insurance/insurancebg-3.jpg" alt="Alternate Text" style="width:26px;height:26px;border-radius:50%"><span
						 class="ml10 gray6 ft14">来自用户balabaBa的提问</span><span class="min gray6 ft14">20分钟前</span></div>
					<div>
						<span class="question-span mr10">答</span>
						<div class="ticket-status position-r">
							<span class="ft16 black">车票或者退票如果遗失，不能退款或更换，只有完全未使用和未被确认的退票、车票、和套票才能退换，最低收取15%的退款费用</span><span
							 class="view">查看</span></div>
					</div>
					<div class="pt10 b-dashed"><img src="../../assets/insurance/insurancebg-3.jpg" alt="Alternate Text" style="width:26px;height:26px;border-radius:50%"><span
						 class="ml10 gray6 ft14">来自一起飞<span class="price">黄晓丽</span>的回答</span><span class="min gray6 ft14">20分钟前</span></div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	var util = require('@/utils/util.js');
	var flightApi = require('@/api/flightApi.js');
	var yqfApi = require('@/api/yqfApi.js');
	var that;

	export default {
		name: 'Home',
		components: {

		},
		data() {
			return {
				titile: '首页',
				imgItem: [{
						img: require('../../assets/insurance/insurancebg-1.jpg'),
					},
					{
						img: require('../../assets/insurance/insurancebg-3.jpg'),
					},
					{
						img: require('../../assets/insurance/visabg-2.jpg'),
					}

				],
				currentNav: 1,
				activeName: 'second',
				value1:""

			};
		},
		created() {
			that = this;


		},
		methods: {
			changePoint(e) {
				console.log(1);
				that.currentNav = e;
			},
			handleClick(tab, event) {
				console.log(tab, event);
			}
		}
	};
</script>

<style lang="scss">
	@import '../../style/Europe/Europe.scss';
</style>
