/*3GAPI接口*/
import {postAjaxRequest} from '@/utils/request'
import {yqfApiurl,yqfComApiurl} from '@/utils/webconfig'

// export var apiurl = "https://3gapitest.yiqifei.com";//测试环境
//export var apiurl = "http://3gapilocal.yiqifei.com"; //本地环境

//提交订单
export function SubmitOrder(parm, callback) {
	console.log('SubmitOrder');
	postAjaxRequest(yqfApiurl+'/MiniAppBooking/SubmitOrder', parm, function(result) {
		callback(result);
	})	
}
//验证码
export function SendBandSMSCode(parm, callback) {
	console.log('SendBandSMSCode');
	postAjaxRequest(yqfApiurl+'/WXMiniAppApi/SendBandSMSCode', parm, function(result) {
		callback(result);
	})	
}
//验证验证码
export function GetCheckVcode(parm, callback) {
	console.log('GetCheckVcode');
	postAjaxRequest(yqfApiurl+'/WXMiniAppApi/GetCheckVcode', parm, function(result) {
		callback(result);
	})	
}
//获取航司列表
export function GetAirlineList(parm, callback) {
	console.log('GetAirlineList');
	postAjaxRequest(yqfApiurl+'/MiniAppUtil/GetAirlineList', parm, function(result) {
		callback(result);
	})	
}
//获取定位
export function GetLocationByIP(parm, callback) {
	console.log('GetLocationByIP');
	postAjaxRequest(yqfApiurl+'/MapApi/GetLocationByIP', parm, function(result) {
		callback(result);
	})	
}

/*一起飞主站API*/

//获取优惠资讯
export function GetYouhuiInfo(parm, callback) {
	console.log('GetYouhuiInfo');
	postAjaxRequest(yqfComApiurl+'/WQGApi/GetYouhuiInfo', parm, function(result) {
		callback(result);
	})	
}
//大家正在查
export function GetSearchingInfo(parm, callback) {
	console.log('GetSearchingInfo');
	postAjaxRequest(yqfComApiurl+'/WQGApi/GetSearchingInfo', parm, function(result) {
		callback(result);
	})	
}
//营业员动态
export function GetAdvisorInfo(parm, callback) {
	console.log('GetAdvisorInfo');
	postAjaxRequest(yqfComApiurl+'/WQGApi/GetAdvisorInfo', parm, function(result) {
		callback(result);
	})	
}
//获取公告
export function GetGongGao(parm, callback) {
	console.log('GetGongGao');
	postAjaxRequest(yqfApiurl+'/WXMiniAppApi/GetAdvisorInfo', parm, function(result) {
		callback(result);
	})	
}

//获取航空公司协议
export function GetAirwaysCustomerRule(parm, callback) {
	console.log('GetAirwaysCustomerRule');
	postAjaxRequest(yqfComApiurl+'/FlightAPI/GetAirwaysCustomerRule', parm, function(result) {
		callback(result);
	})	
}


//获取聊天地址
export function GetIMUrl(parm, callback) {
	console.log('GetIMUrl');
	postAjaxRequest(yqfApiurl+'/WXMiniAppApi/GetIMUrl', parm, function(result) {
		callback(result);
	})	
}
//获取IM的TOKEN
export function GetIMToken(parm, callback) {
	console.log('GetIMToken');
	postAjaxRequest(yqfApiurl+'/WXMiniAppApi/GetIMToken', parm, function(result) {
		callback(result);
	})	
}